import React,{useEffect, useState} from 'react';
import {css} from '@emotion/react';
import BoxProducto from './boxProducto';
import useProductos from '../../hooks/useProductos';

import useResponsive from '../../hooks/useResponsive';
const { device } = useResponsive();

export default function ProductosRel({ctgActual}) {

    const resultado = useProductos();
    const [productos] = useState(resultado);
    const [filtradas,guardarFiltradas] = useState([]);
    useEffect(() => {
  
        const filtro = productos.filter( producto =>
                                            producto.categoria.Nombre === ctgActual.Nombre)
        guardarFiltradas(filtro);

    }, [ctgActual]) // cada vez que haya un cambio en la categoria, el useEffect se va a ejecutar

    return (
        <>
            <ul 
                css={css`
                    display:grid;
                    grid-template-columns:repeat(3, 1fr);
                    column-gap:3rem;
                    padding:2rem 0;
                    @media ${device.tabletAll}{
                        grid-template-columns:repeat(2, 1fr);
                    }
                    @media (max-width: 690px){
                        grid-template-columns:1fr;
                    }
                `}
                >
                {filtradas.map(producto => (
                    <BoxProducto 
                        key={producto.id}
                        data={producto}
                    />
                ))}
            </ul>
        </>
    )
}